/**
 * Toggles the accordion
 */
const toggleAccordion = () => {
  const toggleButton = document.querySelectorAll('.js-toggle-accordion')

  if(toggleButton) {
    toggleButton.forEach(item => {
      item.addEventListener('click', (event) => {
        event.preventDefault();

        const target = item.getAttribute('data-target')
        const content = document.querySelector('#' + target)
        const href = item.getAttribute('href')

        toggleButton.forEach(innerItem => {
          const innerTarget = innerItem.getAttribute('data-target')
          
          if(target === innerTarget && href) {
            innerItem.classList.add('active')
          } else if(target === innerTarget) {
            innerItem.classList.toggle('active')
          }
        })

        if(href && content) {
          content.classList.add('active')
        } else if(content) {
          content.classList.toggle('active')
        }

        if(href) {
          const hrefElement = document.querySelector(href)
          
          if(hrefElement) {
            hrefElement.scrollIntoView()
          }
        }
      })
    })
  }
}

export default toggleAccordion
